// CollaboratorToCompany.jsx
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { ASSOC_COLLAB_TO_COMP, ASSOC_CTOC } from './mutation.gql';
import { GET_COLLABORATOR_COMPANIES } from './query.gql';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth-context';

const CollaboratorToCompany = ({ open, handleClose, idCollaborador }) => {
  const jwt = localStorage.getItem("jwtToken");
  const { companyId } = useContext(AuthContext);
  alert(companyId);

  const _companyID = 1211;
  const [isAlreadyAssociated, setIsAlreadyAssociated] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);

  useEffect(() => {
    if (open) {
      setIsAlreadyAssociated(false);
      setLoadingCheck(true);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      console.log(`Associando colaborador com ID: ${idCollaborador}`);
    }
  }, [idCollaborador, open]);

  const { data, loading, error } = useQuery(GET_COLLABORATOR_COMPANIES, {
    variables: { id: idCollaborador },
    skip: !open || !idCollaborador,
    onCompleted: (data) => {
      if (data && data.collaborator && data.collaborator.data) {
        const associatedCompanies = data.collaborator.data.attributes.companies.data;
        const alreadyAssociated = associatedCompanies.some(
          (comp) => comp.id === companyId.toString()
        );
        setIsAlreadyAssociated(alreadyAssociated);
      }
      setLoadingCheck(false);
    },
    onError: (err) => {
      toast.error(`Erro ao verificar associação: ${err.message}`);
      setLoadingCheck(false);
    }
  });

  // const [assocCollabToComp, { loading: loadingAssoc }] = useMutation(ASSOC_COLLAB_TO_COMP, {
  //   onCompleted: (data) => {
  //     toast.success('Colaborador associado com sucesso!');
  //     handleClose();
  //     // Opcional: Atualizar o estado global ou refetch queries
  //   },
  //   onError: (error) => {
  //     toast.error(`Erro ao associar: ${error.message}`);
  //   }
  // });


  const [assocCollabToComp] = useMutation(ASSOC_CTOC);

  const associarColaborador = async (e) => {
    await assocCollabToComp({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        id: idCollaborador,
        data: [_companyID]
      },

    }).then(async (data) => {

      setLoadingCheck(false);
      toast.success(`Associado com sucesso atualizado`);
      // setSuccessMessage("Associado com sucesso atualizado");
      handleClose();
    })



  }



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
        {loadingCheck ? 'Verificando associação...' : (
          isAlreadyAssociated
            ? 'Este colaborador já está associado à sua empresa.'
            : 'Deseja mesmo associar este colaborador à sua empresa?'
        )}
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {loadingCheck ? (
          <CircularProgress />
        ) : isAlreadyAssociated ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Fechar
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={associarColaborador}
              style={{ marginRight: '10px' }}
              // disabled={loadingAssoc}
            >
              Sim, Associar
              {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'} */}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              // disabled={loadingAssoc}
            >
              Cancelar
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CollaboratorToCompany;

import {
    Autocomplete,
    Box,
    Button, FormControl, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_COMPANIES, GET_COUNTRY_STATE_CITY, GET_POSTROLE } from "../query.gql";
import { CREATE_MEDIA_FILE, DELETE_MEDIA_FILE, UPDATE_COLLABORATORS } from "../mutation.gql";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import i18n from "../../../../translate/i18n";
const CollaboratorStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    introFile,
    setIntroFile,
    setCollaboratorGrid

}) => {
    const filter = createFilterOptions();
    const { selectedLanguage } = useContext(LanguageContext);
    const jwt = localStorage.getItem("jwtToken");
    const [nameError, setNameError] = useState(null);

    const editCollaborator = step?.includes("Collaborator");
    const editIntro = step?.includes("Intro");
    const createColalborator = step?.includes("Create");

    const [updateCollaborator] = useMutation(UPDATE_COLLABORATORS);
    const [deleteAvatar] = useMutation(DELETE_MEDIA_FILE);
    const [uploadAvatar] = useMutation(CREATE_MEDIA_FILE);
    const [getCountries] = useLazyQuery((GET_COUNTRY_STATE_CITY));
    const [countries, setCountries] = useState([]);
    
    const [specialityError, setSpecialityError] = useState(null);
    const [countryError, setCountryError] = useState(null);
    const [country, setCountry] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [speciality, setSpeciality] = useState(null);
    const [cityError, setCityError] = useState(null);
    const filteredCity = cities?.filter(item =>
        item.country?.toLowerCase().includes(country?.name?.toLowerCase())
    )

    const filteredCountry = countries?.filter(item =>
        item.name?.toLowerCase().includes(city?.country?.toLowerCase())
    )

    const [getPostRole] = useLazyQuery(GET_POSTROLE);
    const [postRole, setPostRole] = useState(null);
    const [postRoles, setPostRoles] = useState([]);
    const [postRoleError, setRoleError] = useState(null);

    const [getCompany] = useLazyQuery(GET_COMPANIES);
    const [company, setCompany] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyError, setCompanyError] = useState(null);

    const [fileImg, setFileImg] = useState(null);
    const fileBlob = fileImg?.slice()
    console.log(fileImg?.name, "blob")
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const FILE_TYPE = "image/jpeg";

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [web, setWeb] = useState('');
    const [address, setAddress] = useState('');
    const [intro, setIntro] = useState('');
    //const userID = collaborator.userID
    const [portfolioImgUp, setPortfolioImgUp] = useState("")

    const optionEspeciality = [
        { id: 'frontend', name: 'Front-end' },
        { id: 'backend', name: 'Back-end' }
    ];

    
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const countriesData = await getCountries({
                    fetchPolicy: "cache-first", variables: {
                        pagination: {
                            limit: 1000,
                        },
                    },
                });
                setCountries(countriesData?.data?.countries?.data?.map((item) => {
                    return {
                        id: item.id,
                        name: item.attributes?.name,
                        cities: item.attributes?.cities?.data?.map((item) => {
                            return {
                                id: item.id,
                                name: item.attributes?.name,
                            };
                        })
                    };
                }));
                setCities(countriesData?.data?.countries?.data?.flatMap((country) => {
                    return country.attributes?.cities?.data?.map((city) => {
                        return {
                            id: city.id,
                            name: city.attributes?.name,
                            country: country.attributes?.name
                        };
                    });
                }));
                const postRoleData = await getPostRole({
                    variables: {
                        pagination: {
                            limit: 300,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: "cache-first",
                })
                setPostRoles(postRoleData.data.postRoles.data);
                const companiesData = await getCompany({
                    variables: {
                        pagination: {
                            limit: 300,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: "cache-first",
                })
                setCompanies(companiesData.data.companies.data);
            } catch (error) {
                toast.error("Ocorreu um erro ao carregar a lista de Países");
            }
        };

        fetchData();
        if (step?.includes("Edit")) {
            const foundExpEntity = ObjectList.find((object) => object.id === stepID);


            setCountry({ id: foundExpEntity.countryID, name: foundExpEntity.country })
            setCity({ id: foundExpEntity.cityID, name: foundExpEntity.city })
            let companyName = '';
            if (foundExpEntity.company) {
                companyName = foundExpEntity.company;
            }

            setCompany(foundExpEntity.companies);
            setPostRole({ id: foundExpEntity.postRoleID, attributes: { postRole: foundExpEntity.postRole, description: foundExpEntity.postRoleDescription } })
            setName(foundExpEntity.name)
            setEmail(foundExpEntity.email)
            setPhone(foundExpEntity.phone)
            setWeb(foundExpEntity.web)
            setAddress(foundExpEntity.address)
            setIntro(foundExpEntity.intro)
            setSpeciality(foundExpEntity.speciality)
        }
    }, []);

    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleButtonClick = () => {
        //  Open the file upload dialog
        fileInputRef.current.click();
    };

    const handleUploadChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFileImg(selectedFile);
            setError(i18n.t('collaboratorStep.error.uploadReady', { lng: selectedLanguage }));
        } else {
            setFileImg(null);
            setError(i18n.t('collaboratorStep.error.uploadFile', { lng: selectedLanguage }));
        }
    };


    const handleCollaboratorEdit = () => {
        if (name === "") {
            setNameError(i18n.t('collaboratorStep.error.nameSelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (city === null) {
            setCityError(i18n.t('collaboratorStep.error.citySelect', { lng: selectedLanguage }));
        }
        if (postRole === null) {
            setRoleError(i18n.t('collaboratorStep.error.roleSelect', { lng: selectedLanguage }));
        }
        else {
            let companyArray = company.map(obj => parseInt(obj.id));
            console.log("companyArray", company)
            console.log("companyArray", companyArray)

            updateCollaborator({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                variables: {
                    id: stepID,
                    data: {
                        name: name,
                        address: address,
                        city: city.id,
                        country: country.id,
                        companies: companyArray,
                        postRole: postRole.id,
                        phone: phone,
                        web: web,
                        email: email,
                        speciality: speciality.id,
                    },
                },
            }).then(async (data) => {
                if (fileImg === null) {
                    const foundExpertise =
                    {
                        id: stepID,
                        address: address,
                        city: city.name,
                        cityID: city.id,
                        companies: company,
                        country: country.name,
                        countryID: country.id,
                        email: email,
                        name: name,
                        phone: phone,
                        postRole: postRole.attributes.postRole,
                        postRoleDescription: postRole.attributes.description,
                        postRoleID: postRole.id,
                        web: web,
                        speciality: speciality.id,
                    }

                    console.log("companyArray", foundExpertise)
                    const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);
                    let changedStuff;

                    if (foundExpertiseIndex !== -1) {
                        Object.keys(foundExpertise).forEach(key => {
                            ObjectList[foundExpertiseIndex][key] = foundExpertise[key];
                        });
                        changedStuff = ObjectList[foundExpertiseIndex];
                    }


                }
                else {
                    const fileName = `Avatar of ${name}`; // The filename and extension
                    const fileType = FILE_TYPE; // Set the file type
                    const file = new File([fileBlob], fileName, { type: fileType });
                    const foundExpertise = ObjectList.find((expertise) => expertise.id === stepID);
                    deleteAvatar({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: foundExpertise.avatarID,

                        },
                    })
                    try {
                        const response = await uploadAvatar({
                            context: {
                                headers: {
                                    authorization: `Bearer ${jwt}`,
                                },
                            },
                            variables: {

                                file: file,
                                info: { name: fileName },
                                refId: stepID,
                                field: 'image',
                                ref: 'api::collaborator.collaborator'
                            },
                        })

                        const uploadedFile = response.data.upload.data
                        //console.log("uploadedFile", uploadedFile)
                        setPortfolioImgUp(uploadedFile);
                        const foundExpertise = {
                            id: stepID,
                            address: address,
                            city: city.name,
                            cityID: city.id,
                            companies: company,
                            country: country.name,
                            countryID: country.id,
                            email: email,
                            name: name,
                            phone: phone,
                            postRole: postRole.attributes.postRole,
                            postRoleDescription: postRole.attributes.description,
                            postRoleID: postRole.id,
                            web: web,
                            avatar: uploadedFile.attributes.url,
                            avatarID: uploadedFile.id,
                            speciality: speciality.id

                        };

                        const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);
                        let changedStuff;

                        if (foundExpertiseIndex !== -1) {
                            Object.keys(foundExpertise).forEach(key => {
                                ObjectList[foundExpertiseIndex][key] = foundExpertise[key];
                            });
                            changedStuff = ObjectList[foundExpertiseIndex];
                        }
                    } catch (error) {
                        console.error("Error uploading file:", error);
                    }
                }

                handleClose()
                toast.success("Collaborator Updated!!")
            })

        }

    };
    const handleIntroEdit = () => {
        if (intro === "") {
            setNameError("Intro is Mandatory");
        }
        else {
            updateCollaborator({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                variables: {
                    id: stepID,
                    data: {
                        intro: intro
                    },
                },
            }).then(async (data) => {
                const foundExpertise =
                {
                    id: stepID,
                    intro: intro
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);
                let changedStuff;

                if (foundExpertiseIndex !== -1) {
                    Object.keys(foundExpertise).forEach(key => {
                        ObjectList[foundExpertiseIndex][key] = foundExpertise[key];
                    });
                    changedStuff = ObjectList[foundExpertiseIndex];

                }

                setIntroFile(foundExpertise)
                handleClose()
                toast.success("Intro Updated!!")
            })

        }

    };
    const handleCollaboratorCreate = () => {
        if (name === "") {
            setNameError(i18n.t('collaboratorStep.error.nameSelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setSpecialityError(i18n.t('collaboratorStep.error.especialitySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (city === null) {
            setCityError(i18n.t('collaboratorStep.error.citySelect', { lng: selectedLanguage }));
        }
        if (postRole === null) {
            setRoleError(i18n.t('collaboratorStep.error.roleSelect', { lng: selectedLanguage }));
        }

        else {
            const collaboratorData = {
                name: name,
                phone: phone,
                email: email,
                address: address,
                countryName: country.name,
                countryID: country.id,
                postRole: postRole.attributes.postRole,
                postRoleDescription: postRole.attributes.description,
                postRoleID: postRole.id,
                cityName: city.name,
                cityID: city.id,
                intro: intro,
                web: web,
                image: fileImg?.slice() || null,
                speciality: speciality.id,
            };
            console.log(collaboratorData)
            setCollaborator([collaboratorData]);
            setCollaboratorGrid(false);
        }

    };

    return (


        < >

            {createColalborator && <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >
                        {i18n.t('collaboratorStep.collaborator.nameCollaborator', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={name}
                        onChange={(event) => {
                            handleInputChange(event, setName)
                            setNameError(null)
                        }}
                        fullWidth
                        name="name"
                        id="name"
                        placeholder="Ex: Afrikan Coders"
                    />
                    {nameError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {nameError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >
                        {i18n.t('collaboratorStep.collaborator.address', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={address}
                        onChange={(event) => handleInputChange(event, setAddress)}
                        fullWidth
                        name="address"
                        id="address"
                        placeholder="Ex: Parque Tecnologico Nº12"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">
                        {i18n.t('collaboratorStep.collaborator.country', { lng: selectedLanguage })}
                    </InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={filteredCountry.length > 0 ? filteredCountry : countries}
                            value={country}
                            getOptionLabel={(country) => country.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCountry(newValue)
                                setCountryError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.countryBody', { lng: selectedLanguage })}
                                    required
                                />
                            )}
                        />
                        {countryError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {countryError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">
                        {i18n.t('collaboratorStep.collaborator.city', { lng: selectedLanguage })}
                    </InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={filteredCity.length > 0 ? filteredCity : cities}
                            value={city}
                            getOptionLabel={(city) => city.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCity(newValue)
                                setCityError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.cityBody', { lng: selectedLanguage })}
                                    required
                                />
                            )}
                        />
                        {cityError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {cityError}
                        </Typography>}
                    </FormControl>

                </Grid>


                <Grid item xs={12} md={6}>
                    <InputLabel id="select-postRoles">
                        {i18n.t('collaboratorStep.collaborator.role', { lng: selectedLanguage })}
                    </InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={postRoles}
                            value={postRole}
                            getOptionLabel={(country) => country.attributes.description}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setPostRole(newValue);
                                setRoleError(null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.roleBody', { lng: selectedLanguage })}
                                    required


                                />
                            )}
                        />
                        {postRoleError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {postRoleError}
                        </Typography>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >
                        {i18n.t('collaboratorStep.collaborator.phone', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={phone}
                        onChange={(event) => handleInputChange(event, setPhone)}
                        fullWidth
                        name="phone"
                        id="phone"
                        placeholder="Ex: +238 5802943"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >
                        {i18n.t('collaboratorStep.collaborator.web', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={web}
                        onChange={(event) => handleInputChange(event, setWeb)}
                        fullWidth
                        name="web"
                        id="web"
                        placeholder="Ex: https://www.afrikancoders.com/"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >
                        {i18n.t('collaboratorStep.collaborator.email', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField
                        size="small"
                        required
                        autoFocus
                        type="text"
                        value={email}
                        onChange={(event) => handleInputChange(event, setEmail)}
                        fullWidth
                        name="email"
                        id="email"
                        placeholder="Ex: african@koders.com"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-postRoles">
                        {i18n.t('collaboratorStep.collaborator.introduction', { lng: selectedLanguage })}
                    </InputLabel>
                    <TextField
                        size="small"
                        required
                        autoFocus
                        multiline
                        type="text"
                        value={intro}
                        onChange={(event) => handleInputChange(event, setIntro)}
                        fullWidth
                        name="intro"
                        id="intro"
                        placeholder={i18n.t('collaboratorStep.collaborator.introductionBody', { lng: selectedLanguage })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-especiality">
                        {i18n.t('collaboratorStep.collaborator.especiality', { lng: selectedLanguage })}
                    </InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={optionEspeciality}
                            value={speciality}
                            getOptionLabel={(speciality) => speciality.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setSpeciality(newValue)

                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('Ex: Front-end', { lng: selectedLanguage })}
                                    required
                                />

                            )}
                        />
                        {specialityError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {specialityError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel>
                        {i18n.t('collaboratorStep.collaborator.upload', { lng: selectedLanguage })}
                    </InputLabel>
                    <Box >
                        <input type="file" id="file" onChange={handleUploadChange} ref={fileInputRef} style={{ display: 'none' }} />
                        <Button fullWidth startIcon={<DriveFolderUploadIcon />} variant="contained" color="primary" onClick={handleButtonClick}>
                            {i18n.t('collaboratorStep.button.upload', { lng: selectedLanguage })}
                        </Button>
                        {error && <Typography variant="body2" color="error">{error}</Typography>}
                    </Box>


                </Grid>



            </Grid>}





















            {editCollaborator && <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.nameCollaborator', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={name}
                        onChange={(event) => {
                            handleInputChange(event, setName)
                            setNameError(null)
                        }}
                        fullWidth
                        name="name"
                        id="name"
                        placeholder="Ex: Afrikan Coders"
                    />
                    {nameError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {nameError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.address', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={address}
                        onChange={(event) => handleInputChange(event, setAddress)}
                        fullWidth
                        name="address"
                        id="address"
                        placeholder="Ex: Parque Tecnologico Nº12"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">{i18n.t('collaboratorStep.collaborator.country', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={filteredCountry.length > 0 ? filteredCountry : countries}
                            value={country}
                            getOptionLabel={(country) => country.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCountry(newValue)
                                setCountryError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.countryBody', { lng: selectedLanguage })}
                                    required
                                />
                            )}
                        />
                        {countryError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {countryError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">{i18n.t('collaboratorStep.collaborator.city', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={filteredCity.length > 0 ? filteredCity : cities}
                            value={city}
                            getOptionLabel={(city) => city.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCity(newValue)
                                setCityError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.cityBody', { lng: selectedLanguage })}
                                    required
                                />
                            )}
                        />
                        {cityError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {cityError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-postRoles">{i18n.t('collaboratorStep.collaborator.company', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            size="small"
                            options={companies}
                            value={company}
                            getOptionLabel={(company) => company.attributes.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCompany(newValue);
                                setCompanyError(null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Ex: Sintaxy"
                                    required


                                />
                            )}
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-postRoles">{i18n.t('collaboratorStep.collaborator.role', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={postRoles}
                            value={postRole}
                            getOptionLabel={(country) => country.attributes.description}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setPostRole(newValue);
                                setRoleError(null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.roleBody', { lng: selectedLanguage })}
                                    required


                                />
                            )}
                        />
                        {postRoleError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {postRoleError}
                        </Typography>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.phone', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={phone}
                        onChange={(event) => handleInputChange(event, setPhone)}
                        fullWidth
                        name="phone"
                        id="phone"
                        placeholder="Ex: +238 5802943"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.web', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={web}
                        onChange={(event) => handleInputChange(event, setWeb)}
                        fullWidth
                        name="web"
                        id="web"
                        placeholder="Ex: https://www.afrikancoders.com/"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.email', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={email}
                        onChange={(event) => handleInputChange(event, setEmail)}
                        fullWidth
                        name="email"
                        id="email"
                        placeholder="Ex: african@koders.com"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel>
                        {i18n.t('collaboratorStep.collaborator.newUpload', { lng: selectedLanguage })}
                    </InputLabel>
                    <Box >
                        <input type="file" id="file" onChange={handleUploadChange} ref={fileInputRef} style={{ display: 'none' }} />
                        <Button fullWidth startIcon={<DriveFolderUploadIcon />} variant="contained" color="primary" onClick={handleButtonClick}>
                            {i18n.t('collaboratorStep.button.upload', { lng: selectedLanguage })}
                        </Button>
                        {error && <Typography variant="body2" color="error">{fileImg?.name}{error}</Typography>}
                    </Box>


                </Grid>

            </Grid>}
            {editIntro && <Grid item xs={12} md={12}>
                <InputLabel >{i18n.t('collaboratorStep.collaborator.introduction', { lng: selectedLanguage })}</InputLabel>
                <TextField
                    multiline
                    style={{ width: '500px' }}
                    required
                    type="text"
                    value={intro}
                    onChange={(event) => handleInputChange(event, setIntro)}
                    name="intro"
                    id="intro"
                    placeholder={i18n.t('collaboratorStep.collaborator.introductionBody', { lng: selectedLanguage })}
                />

            </Grid>}

            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {createColalborator &&
                        <Grid item>
                            <Button
                                variant="outlined"
                                type="button"
                                color="primary"
                                onClick={handleCollaboratorCreate}
                            >
                                {i18n.t('collaboratorStep.button.collaborator', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        {!createColalborator &&
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleClose}>
                                {i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}
                            </Button>}
                    </Grid>
                    <Grid item>

                        {editIntro && <Button
                            variant="contained"
                            size="small"
                            onClick={handleIntroEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {editCollaborator && <Button
                            variant="contained"
                            size="small"
                            onClick={handleCollaboratorEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}

                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default CollaboratorStep;
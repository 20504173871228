import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Grid, Button, TextField, Alert, AlertTitle, Snackbar } from '@mui/material';
//import { toast } from 'react-toastify';
//import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useState, useEffect } from "react";
//import i18n from "../../../translate/i18n";
import {
  UPDATE_EVAL_OPEN, CREATE_EVAL_SCORE, CREATE_PERIODS_COLLABORATOR, CREATE_OPEN_ANSWER, UPDATE_EVAL_SCORE, DELETE_EVAL_SCORE,
  DELETE_EVAL_OPEN
} from '../mutation'

import { useMutation } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';

export default function ContentStepper({
  step,
  hero,
  btnState,
  showEvals,
  setShowEvals,
  matchSteps,
  duplicatSteps,
  answer_Id,
  score_Id,
  question_Id,
  i_,
  setI_,
  company_id,

}) {
  //const { selectedLanguage } = useContext(LanguageContext);
  const [createOpenEval] = useMutation(CREATE_OPEN_ANSWER)
  const [create_Eval] = useMutation(CREATE_EVAL_SCORE)
  const [updateOpenEval] = useMutation(UPDATE_EVAL_OPEN)
  const [update_Eval] = useMutation(UPDATE_EVAL_SCORE)
  const [delete_Eval] = useMutation(DELETE_EVAL_SCORE)

  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const jwt = localStorage.getItem("jwtToken");
  const [ids_closed, setIds_closed] = useState()
  const [eval__id, seteval__id] = useState(null)


  const questions_O = matchSteps?.attributes?.evaluation_question_opens?.data
  const questions_CL = matchSteps?.attributes?.evaluation_question_closeds?.data
  const questions_ = questions_O?.concat(questions_CL)
  const cIDS = matchSteps?.attributes?.evaluation_question_closeds.data
  const [qIDC, setQIDC] = useState(null)
  const [qIDO, setQIDO] = useState([])
  const [score_, setScore_] = useState(false)

  const initialEvalData = duplicatSteps.map((xx, stepIndex) =>
    xx.attributes?.evaluation_question_opens?.data?.map((vx, idx) => ({
      answer: '',
      evaluation_question_open: vx.id,
      ansid: vx.attributes?.evaluation_answer_opens.data.map((cx) => cx.id)
    }))
  )
  const [heroid__, setHeroid__] = useState(null)
  const [evalData, setEvalData] = useState(initialEvalData);

  useEffect(() => {
    if (btnState === true) {
      setShowEvals(true)
    }
    hero.score.map((tx) => {
      if (tx.eval_period.id === i_) {
        setScore_(tx.eval_period.id === i_)
        seteval__id(tx.eval_period.id)
      }
    })

    hero?.companies.map((vx) =>
      vx.evalsData.map((xx) => {
        if (xx.id === eval__id) {
          setHeroid__(xx.id)
        }
      })

    )



  }, [btnState, step, matchSteps.length, heroid__])



  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };
  const [checkedActive, setCheckedActive] = useState(null)

  const runchanges = (i, idData, e, idn) => {
    setCheckedActive(0);
    const idData_ = idData.attributes.evaluation_answer_closeds.data
    if (i > 0) {
      setCheckedActive((prev) => (prev === i ? null : i));
    }

    setIds_closed(idData_[i].id)

  }

  //console.log(ids_closed)
  const [ans_Val, setAns_Val] = useState([])
  const evaluationQuestionOpenValues = [];
  const evaluationAnswerOpenValues = [];

  function handleChange(stepIndex, questionIndex, event) {
    const updatedEvalData = [...evalData];
    updatedEvalData[stepIndex][questionIndex].answer = event.target.value;
    setEvalData([...updatedEvalData]);
  }

  useEffect(() => {
    cIDS?.map((tx, idx) => {
      console.log("tx", tx);
      
      return setQIDC(tx.id)
    }
    )

  }, [cIDS]);



  useEffect(() => {

    for (let i = 0; i < evalData?.length; i++) {
      for (let idx = 0; idx < evalData[i].length && i <= step; idx++) {
        evaluationAnswerOpenValues.push(evalData[i][idx].answer)
        evaluationQuestionOpenValues.push(evalData[i][idx].evaluation_question_open)
      }

    }

    setQIDO(evaluationQuestionOpenValues)
    setAns_Val(evaluationAnswerOpenValues)

  }, [evalData, duplicatSteps])

  const itemSubmit = async () => {
    const updateAns__ = []
    //Iterate over unique qIds
    const answers__ = []

    try {

      if (score_ === true) {
        for (let i = 0; i < answer_Id.length || i < question_Id[0].length || ids_closed; i++) {
          await updateOpenEval({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              id: answer_Id[i],
              data: {
                evaluation_question_open: question_Id[0][i],
                answer: ans_Val[i]
              },
            },

          }).then(async (data) => {
            console.log(data.data, "dados")
            const ansid = data?.data?.updateEvaluationAnswerOpen?.data?.id
            updateAns__.push(ansid)
            setOpenAlertOK(true)
            setSuccessMessage("Atualizado");
            //setEvalData([])

            //setEvalData([])
          }).catch((error) => {
            console.log("ocorreu um erro", error);
          })

        }
      } else {
        for (let i = 0; i < qIDO.length || i < ans_Val.length; i++) {
          await createOpenEval({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              data: {
                evaluation_question_open: qIDO[i],
                answer: ans_Val[i],
              },
            },
          }).then(async (data) => {

            const ansid = await data?.data?.createEvaluationAnswerOpen?.data?.id
            answers__.push(ansid)
            console.log(ansid, "dados criou")

            setOpenAlertOK(true)
            setSuccessMessage("Atualizado");

            //setAns_id(prev => [...prev, data?.data?.createEvaluationAnswerOpen?.data?.id])
            //setEvalData([])
          }).catch((error) => {
            console.log("ocorreu um erro", error);
          })

        }

      }


      if (score_ === false) {
        create_Eval({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },

          variables: {

            data: {
              company: company_id,
              collaborator: hero.id,
              evaluation_period: i_,//eval_prdID[0],evalID_data[0][0].id,,
              evaluation_question_opens: question_Id[0],
              evaluation_question_closeds: qIDC,
              evaluation_answer_opens: answers__,
              evaluation_answer_closeds: ids_closed,
            },
          },
        }).then((data) => {
          console.log(data.data)
          console.log(answers__)
          setOpenAlertOK(true)
          setSuccessMessage("Atualizado");
        }).catch((error) => {
          console.log(error);
        })

      } else {
        update_Eval({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: score_Id[0],
            data: {
              company: company_id,
              collaborator: hero.id,
              evaluation_period: eval__id,//evalID_data[0][0].id,
              evaluation_question_opens: question_Id[0],
              evaluation_question_closeds: qIDC,
              evaluation_answer_opens: answer_Id.concat(answers__),//get ids from state
              evaluation_answer_closeds: ids_closed,

            },
          },
        }).then((data) => {
          console.log(data.data)
          setOpenAlertOK(true)
          setSuccessMessage("Atualizado");
        }).catch((error) => {
          console.log(error);
        })

      }


    } catch (err) {
      console.log(err)
    }
  }
  const numAscending = duplicatSteps.map((tx) => [...tx.attributes?.evaluation_question_opens?.data].sort((a, b) => a.id - b.id))
console.log("respostas", duplicatSteps);
console.log("answer_Id", answer_Id);

  return (
    <Box >
      <Grid

        item
        container
        sx={{ pl: 4 }}
        alignItems="left"
        justifyContent="left"
      >{showEvals &&
        <Grid item style={{}}>
          <Stepper orientation="vertical">
            <Step>

              {duplicatSteps?.map((xx, stepIndex) =>
                xx.attributes?.evaluation_question_opens?.data?.map((vx, questionIndex) =>
                  vx.attributes.type === "Open" && step === stepIndex ? (
                    <Box key={questionIndex}>
                      <Typography component='h3' variant='h3' style={{ fontSize: 16, marginLeft: 9 }}>
                        {vx.attributes.question}
                      </Typography>

                      <Grid container margin={2}>
                        <TextField
                          type="text"
                          fullWidth
                          name={'answer'}
                          id={'answer'}
                          size="medium"
                          value={evalData[stepIndex][questionIndex]?.answer || ''}
                          onChange={(e) => handleChange(stepIndex, questionIndex, e)}
                        />
                      </Grid>
                    </Box>
                  ) : null
                )
              )
              }
              {duplicatSteps?.map((xx, stepIndex) =>
                xx.attributes?.evaluation_question_closeds?.data?.map((ix, questionIndex) =>
                  step === stepIndex ?
                    <Box key={stepIndex}>
                      <Typography component='h3' variant='h3' style={{ fontSize: 16, marginLeft: 9 }}>
                        {ix.attributes.question}
                      </Typography>

                      {ix?.attributes?.evaluation_answer_closeds?.data?.map((xy, i) =>
                        <Box key={i} paragraph={true} style={{ display: 'flex', color: '#333' }}>

                          <Checkbox
                            onChange={(e) => runchanges(i, ix, e, stepIndex)}
                            checked={i === checkedActive}
                            inputProps={{
                              'aria-label': 'Checkbox A',
                            }}
                            value={false}
                            sx={{
                              '&.Mui-checked': {
                              },
                            }}
                          />
                          <Typography style={{ fontWeight: 500, fontSize: 15, marginTop: 5, paddingTop: 5, color: '#333' }}>{xy?.attributes?.answer}
                          </Typography>
                        </Box>
                      )}

                    </Box> : null
                ))}


            </Step>
          </Stepper>
        </Grid>}
        {!showEvals &&
          <Grid item style={{}}>
            <Stepper orientation="vertical">
              <Step>
                <Typography component='h3' variant='h3' sx={{ pb: 3, pt: 1, }}>Period {i_} </Typography>
                {duplicatSteps?.map((xx, stepIndex) =>
                  xx.attributes?.evaluation_question_opens?.data?.map((vx, questionIndex) =>
                    vx.attributes.type === "Open" && step === stepIndex ? (
                      <Box key={questionIndex} style={{ marginTop: 22 }}>
                        <Typography component='h3' variant='h3' style={{ fontSize: 16, marginLeft: 9 }}>
                          {vx.attributes.question}
                        </Typography>
                        {vx.attributes.evaluation_answer_opens.data.map((xx, questionIndex_) => (
                          <Grid container margin={2} key={questionIndex_}>
                            <Typography paragraph={true} style={{ fontSize: 16 }}>
                              {answer_Id?.includes(xx.id) ? xx.attributes.answer : ''}
                            </Typography>
                          </Grid>
                        )

                        )}

                      </Box>
                    ) : null
                  )
                )
                }

              </Step>
            </Stepper>
          </Grid>}


      </Grid>
      <Grid
        item
        container
        spacing={-20}
        alignItems="center"
        direction="column"
        justifyContent="center"
        sx={{ position: "absolute", bottom: 21 }}
      >
        {btnState && <Button variant="contained"
          type="submit"
          color="primary"
          onClick={itemSubmit} >
          {!score_ ? 'Save' : 'Update'}

        </Button>}
      </Grid>
      <Snackbar
        style={{ backgroundColor: 'teal', color: 'coral' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlertOK}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert severity="success" sx={{ border: 1 }}>
          <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}>
            Successo <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} /> </AlertTitle>
          {successMessage}

        </Alert>
      </Snackbar>

    </Box>
  );
}

